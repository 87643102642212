@import url("https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap");
.expertise-custom-style {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", serif;
  font-size: 20px;
  width: 80%;
  margin: auto;
  padding: 30px 15px;
  text-align: justify;
}

@media only screen and (max-width: 576px) {
  /* other rules */
  .expertise-custom-style {
    font-size: 13px; /* Adjust as needed */
    padding: 5px; /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", serif;
    width: 80%;
    margin: auto;
    padding: 0 15px; // Add some space around the text on smaller screens
    text-align: justify;
  }
}
